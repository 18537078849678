/**
 *
 * Sections
 *
 * body, section, nav, article, aside,
 * h{1-6}, header, footer, address
 *
 */

html {
    font-size: 16px;
}


/*============================
=            body            =
============================*/

body {
    font-family: var(--font-sans);
    background-color: var(--theme-bg);
    font-size: 15px;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: .07em;
    font-feature-settings: 'palt';
    vertical-align: middle;
    /*  fontのアンチエイリアス  */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/*=====  End of body  ======*/


/*=========================
=            h{1-6}       =
=========================*/

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-weight: bold;
}


/*=====  End of h{1-6}  ======*/


/*==============================
=            header            =
==============================*/

#header {
    width: 100%;
    height: calc(106px/2);
    padding-left: .5rem;
    padding-right: .5rem;
    @media (--md) {
        height: 80px;
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

#header {
    & .brand {
        width: calc(404px/2);
        height: calc(43px/2);
        @media (--sm) {
            width: 290px;
            height: 32px;
        }
    }
    & img {
        vertical-align: top;
    }
}

@media (min-width: 832px) and (max-width: 1000px) {
    #header {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        & .brand {
            width: calc(290px * .95);
            height: calc(32px * .95);
        }
    }
}


/*=====  End of header  ======*/


/*==============================
=            footer            =
==============================*/

#footer {
    background-color: var(--theme-gray);
}

#footer {
    & .copyright {
        font-size: calc(18px/2);
        letter-spacing: 0;
        line-height: calc(24/13);
        @media (--md) {
            font-size: 13px;
        }
    }
}


/*=====  End of footer  ======*/
