/**
 *
 * Theme Color Class
 *
 */

.theme-bg {
    color: var(--theme-bg);
}

.theme-primary {
    color: var(--theme-primary);
}

.theme-gray {
    color: var(--theme-gray);
}

.bg-theme-bg {
    background-color: var(--theme-bg);
}

.bg-theme-primary {
    background-color: var(--theme-primary);
}

.bg-theme-gray {
    background-color: var(--theme-gray);
}

.bg-floral {
    background-color: var(--c_floral);
}
