/**
 *
 * Text-Level Semantics
 *
 * a, q, cite, em, strong, small, mark, dfn,
 * abbr, time, progress, meter, code, var,
 * samp, kbd, sub, sup, span, i, b, bdo,
 * ruby, rt, rp
 *
 */

a, .btn, [data-href] {
    transition: all .3s;
    &:hover {
        @media (--md) {
            opacity: .6;
        }
    }
    &:active, &:hover, &:visited, &:focus {
        outline: 0;
        box-shadow: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}
