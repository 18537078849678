/**
 *
 * page-shop
 *
 *
 */


/*==============================
=            common            =
==============================*/

.entry-body {
    letter-spacing: calc(25em/1000);
    line-height: calc(48/30);
    @media (--md) {
        font-size: 18px;
        line-height: calc(36/18);
    }
    & *:first-child {
        margin-top: 0;
    }
    & *:last-child {
        margin-bottom: 0;
    }
    & iframe {
        max-width: 864px;
    }
    & img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 486px;
    }
}


/*=====  End of common  ======*/


/*==================================
=            news page           =
==================================*/

.news-entry-date {
    line-height: 1.2;
    font-size: calc(30px/2);
    @media (--md) {
        font-size: 18px;
    }
}

.news-entry-title {
    font-size: calc(36px/2);
    @media (--md) {
        font-size: 24px;
    }
}

.news-pagination {
    &::before {
        display: block;
        content: '';
        width: 1px;
        height: .8em;
        background-color: var(--c_black);
        position: absolute;
        top: calc(50% + .4em);
        left: 50%;
        transform: translateX(-50%);
    }
}

.news-pagination {
    & span {
        padding-bottom: 2px;
    }
}


/*=====  End of news page  ======*/


/*==================================
=            shop page           =
==================================*/

.shop-entry-heading {
    font-size: calc(30px/2);
    letter-spacing: calc(50em/1000);
    @media (--md) {
        font-size: 20px;
    }
}

.shop-entry-article {
    & hr {
        border-color: var(--c_black);
    }
}

.shop-info-heading {
    font-size: calc(30px/2);
    border-bottom: 1px solid var(--c_black);
    @media (--md) {
        font-size: 16px;
        border: 0;
    }
}

.shop-info-list {
    @media (--md) {
        font-size: 18px;
        letter-spacing: calc(25em/1000);
    }
}

.shop-info-list {
    & .info-url {
        word-wrap: break-word;
    }
}


/*=====  End of shop page  ======*/
