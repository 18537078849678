/**
 *
 * page-news
 *
 *
 */


/*==============================
=            common            =
==============================*/


/*=====  End of common  ======*/


/*==================================
=            news page           =
==================================*/

.news-index {
    & .md-col-9 {
        @media (--in-sm) {
            margin-left: -3px;
            margin-right: -3px;
        }
    }
}

.news-index {
    & .entry {
        border: 3px solid transparent;
        border-top: 0;
        border-bottom: 0;
        @media (--md) {
            border: 0;
        }
    }
}

.news-index {
    & .news-entry-mv {
        width: 100%;
        height: 0;
        padding-top: calc(238/366*100%);
        background-position: center;
        background-size: cover;
        @media (--md) {
            padding-top: calc(486/864*100%);
        }
    }
}

.news-index {
    & .news-entry-heading {
        line-height: 1;
        font-size: calc(22px/2);
        @media (--md) {
            font-size: 18px;
        }
    }
}

.news-index {
    & .news-entry-heading {
        & .date {
            margin-bottom: .4rem;
        }
    }
}

.news-index {
    & .pagination {
        & .next-item {
            margin-right: -1em;
        }
        & .prev-item {
            margin-left: -1em;
        }
    }
}


/*=====  End of news page  ======*/


/*==================================
=            shop page            =
==================================*/

.sec-next_tenants {
    @media (--md) {
        margin-left: -2px;
        margin-right: -2px;
    }
}

.shop-entry {
    border-color: transparent;
    @media (--md) {
        border-width: 2px;
    }
}


/* 各ジャンルのエントリー最後の２つは border-bottom 0 */

.genre-wrap {
    & .md-col2:nth-last-child(-n+2) .shop-entry {
        @media (--in-sm) {
            border-bottom: 0;
        }
    }
}

.shop-entry-thumb {
    width: 100%;
    height: 0;
    padding-top: 100%;
    @media (--md) {
        padding-top: calc(168/252*100%);
    }
}

.shop-entry-text {
    @media (--in-sm) {
        font-size: calc(22px/2);
        background-color: rgba(0, 0, 0, .5);
        color: var(--c_white);
        position: absolute;
        bottom: 0;
        line-height: 1.4;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    @media (--md) {
        font-size: 15px;
    }
}

.entry-text-upper {
    @media (--in-sm) {
        margin-bottom: 1px;
    }
}


/*----------  sec-partners  ----------*/

.shop-partner-thumb {
    width: 100%;
    height: 0;
    padding-top: calc(310/375*100%);
    @media (--md) {
        padding-top: calc(168/252*100%);
    }
}

.shop-partner-text {
    @media (--in-sm) {
        font-size: calc(20px/2);
        line-height: calc(29/20);
    }
}

.shop-partner-label {
    border-color: inherit;
    margin-bottom: .2rem;
    @media (--md) {
        font-size: 15px;
    }
}


/*=====  End of shop page  ======*/
