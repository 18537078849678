/**
 *
 * page-header
 *
 *
 */


/*----------  decoration  ----------*/

.header-deco::before, .header-deco::after {
    display: block;
    content: '';
    width: calc(48px/2);
    height: calc(40px/2);
    background-image: url(../images/deco-header.svg);
    background-size: calc(48px/2) auto;
    background-repeat: no-repeat;
    position: absolute;
    @media (--md) {
        width: 48px;
        height: 39px;
        background-size: 48px auto;
    }
}

.header-deco-1::before {
    top: calc(28px/2);
    left: calc(30/750*100%);
    @media (--md) {
        top: 20px;
        left: calc(80/1280*100%);
    }
}

.header-deco-1::after {
    top: calc(180px/2);
    left: calc(-18/750*100%);
    @media (--md) {
        top: 136px;
        left: calc(20/1280*100%);
    }
}

.header-deco-2::before {
    top: calc(28px/2);
    left: calc(280/750*100%);
    @media (--md) {
        top: 20px;
        left: calc(318/1280*100%);
    }
}

.header-deco-2::after {
    top: calc(180px/2);
    left: calc(218/750*100%);
    @media (--md) {
        top: 136px;
        left: calc(260/1280*100%);
    }
}

.header-deco-3::before {
    top: calc(28px/2);
    left: calc(516/750*100%);
    @media (--md) {
        top: 20px;
        left: calc(797/1280*100%);
    }
}

.header-deco-3::after {
    top: calc(180px/2);
    left: calc(457/750*100%);
    @media (--md) {
        top: 136px;
        left: calc(980/1280*100%);
    }
}

.header-deco-4::before {
    top: calc(28px/2);
    left: calc(752/750*100%);
    @media (--md) {
        top: 20px;
        left: calc(1040/1280*100%);
    }
}

.header-deco-4::after {
    top: calc(180px/2);
    left: calc(698/750*100%);
    @media (--md) {
        top: 136px;
        left: calc(1220/1280*100%);
    }
}


/*----------  contact page  ----------*/

.sec-wanted-heading {
    & .header-deco::after {
        top: calc(170px/2);
        @media (--md) {
            top: 136px;
        }
    }
}
