/**
 *
 * page-contact
 *
 *
 */


/*==============================
=            common            =
==============================*/


/*=====  End of common  ======*/


/*----------  sec-wanted  ----------*/

.sec-wanted-heading {
    padding-top: calc(24px/2);
    padding-bottom: calc(20px/2);
    @media (--md) {
        padding-top: 15px;
        padding-bottom: 20px;
    }
}

.sec-wanted {
    &::before {
        display: block;
        content: '';
        width: 100%;
        height: calc(100vh - 53px);
        max-height: calc(1100/750*100vw);
        background-image: url(../images/top-mv.jpg);
        background-size: cover;
        background-position: center;
        position: absolute;
        z-index: -1;
        @media (--md) {
            height: calc(100vh - 80px);
            background-image: url(../images/top-mv-lg.jpg);
        }
    }
}

.wanted-text {
    letter-spacing: calc(25em/1000);
    line-height: calc(48/30);
    background-color: rgba(255, 255, 255, .8);
    @media (--md) {
        line-height: calc(36/18);
    }
}

.wanted-text {
    & h3 {
        font-size: 15px;
        line-height: calc(48/30);
        @media (--md) {
            font-size: 20px;
            margin-top: -.5em;
        }
    }
}

.wanted-text {
    & p {
        margin-top: 0;
        @media (--md) {
            font-size: 18px;
        }
    }
}

.wanted-text {
    & p.my0 {
        margin-bottom: -.35em;
        @media (--md) {
            margin-bottom: -1.6em;
        }
    }
}


/*----------  sec-contact  ----------*/

.form-container {
    font-size: calc(36px/2);
    @media (--md) {
        font-size: 23px;
        letter-spacing: calc(50em/1000);
        padding-left: 80px;
        max-width: 590px;
    }
}

.form-dd-btn {
    padding-left: 50px;
    border: 2px solid var(--c_gray);
    margin-bottom: -2px;
    &:focus, &:active, &:hover {
        box-shadow: none;
        opacity: 1;
        border-color: var(--c_gray);
    }
}

.form-dd-btn {
    & .arrow-down {
        margin-left: 50px;
        width: calc(100px - 2px*2);
        height: calc(100px - 2px*2);
        background-image: url(../images/icon-arrow-down.svg);
        background-size: 48px auto;
        background-position: center;
        border-left: 2px solid var(--c_gray);
    }
}

.form-dd-list {
    margin-bottom: 60px;
    @media (--md) {
        border: 2px solid var(--c_gray);
    }
}

.form-dd-item {
    border-width: 2px;
    border-color: var(--c_gray);
    @media (--in-sm) {
        text-align: center;
        border-width: 1px;
        border-top: 0;
    }
}

.form-dd-link {
    @media (--md) {
        color: var(--c_gray);
        padding-left: 50px;
        line-height: calc(100px - 2px*2);
    }
}
