/**
 *
 * page-top
 *
 *
 */


/*----------  page top common  ----------*/

.top {
    & .entry {
        margin-left: -.25rem;
        margin-right: -.25rem;
    }
}

.top {
    & .entry-title {
        font-size: 1em;
    }
}

.top {
    & .entry-text {
        line-height: 1;
    }
}

.top {
    & .entry-thumb {
        background-size: cover;
        background-position: center;
    }
}

.top {
    & .sec-anchor {
        @media (--md) {
            top: -80px;
        }
    }
}


/*----------  kv section  ----------*/

.top-mv-area {
    height: calc(100vh - 53px);
    max-height: calc(1100/750*100vw);
    padding-top: 0;
    background-image: url(../images/top-mv.jpg);
    background-position: center 32%;
    @media (--md) {
        height: calc(100vh-80px);
        padding-top: 0;
        background-image: url(../images/top-mv-lg.jpg);
    }
}

.top-mv-area {
    & .mv-logo {
        width: calc(558/750*100%);
        @media (--md) {
            width: calc(558/1280*100%);
            min-width: 558px;
        }
    }
}


/*----------  #sec01 features section  ----------*/

.top-sec-features {
    & .feature-thumb {
        padding-top: calc(426/750*100%);
        @media (--md) {
            background-image: none;
            padding-top: calc(280/422*100%);
        }
    }
}

.top-sec-features {
    & .entry-label {
        border-bottom: 1px solid var(--c_black);
        @media (--in-sm) {
            padding: 0 .4rem .2rem 0;
        }
        @media (--md) {
            border: 2px solid var(--c_black);
        }
    }
}

.top-sec-features {
    & .entry-title {
        margin-top: .5rem;
        margin-bottom: .25rem;
        @media (--md) {
            /* margin-top: .rem; */
        }
    }
}

.top-sec-features {
    & .entry-text p {
        @media (--md) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}


/*----------  #sec02 news section  ----------*/

.top-sec-news {
    & .news-thumb {
        padding-top: calc(426/750*100%);
        @media (--md) {
            padding-top: 100%;
        }
    }
}

.top-sec-news {
    & .entry-title {
        margin-top: .3rem;
    }
}


/*----------  #sec03 concept section  ----------*/

.top-sec-concept {
    & .concept-text * {
        font-size: 15px;
    }
}

.top-sec-concept {
    & .concept-text h3 {
        line-height: calc(48/30);
        @media (--md) {
            font-size: 20px;
        }
    }
}

.top-sec-concept {
    & .concept-text p {
        line-height: calc(48/30);
        letter-spacing: calc(25em/1000);
        @media (--md) {
            font-size: 18px;
            line-height: calc(36/18);
        }
    }
}

.top-sec-concept {
    & p.my0 {
        margin-bottom: -.35em;
        s @media (--md) {
            margin-bottom: -.6em;
        }
    }
}
