/**
 *
 * entry
 *
 *
 */

.entry[data-href] {
    cursor: pointer;
}

.entry-detail-title {
    font-size: calc(36px/2);
    @media (--md) {
        font-size: 24px;
    }
}

.entry-thumb {
    background-size: cover;
    background-position: center;
}

.entry-label {
    line-height: 1;
    padding: .1rem .6rem;
    @media (--md) {
        border: 2px solid var(--c_black);
    }
}
