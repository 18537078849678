/**
 *
 * miscs
 *
 *
 */

.font-size-default {
    font-size: 15px;
}

.font-yugo {
    font-family: var(--font-yugo);
}

.event-none {
    pointer-events: none;
}

.md-center {
    @media (--md) {
        text-align: center;
    }
}

.px05 {
    margin-left: -.25rem;
    margin-right: -.25rem;
}

.ls0 {
    letter-spacing: 0;
}

.no-repeat {
    background-repeat: no-repeat;
}

.insm-hide {
    @media (--in-sm) {
        display: none !important
    }
}
