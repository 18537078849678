/**
 *
 * Additional helper for Ace.css
 * Ace.cssの補完クラス
 *
 */


/* flexbox */

.inline-flex {
    display: inline-flex;
}

.flex-1 {
    /* flex: 1 1 0% */
    flex: 1;
}

.row-reverse {
    flex-direction: row-reverse;
}

.col-reverse {
    flex-direction: column-reverse;
}


/* Background Images */

.bg-contain {
    background-repeat: no-repeat;
}


/* Responsive border */

@media (min-width: 40em) {
    .sm-border {
        border-style: solid;
        border-width: 1px;
    }
    .sm-border-top {
        border-top-style: solid;
        border-top-width: 1px;
    }
    .sm-border-right {
        border-right-style: solid;
        border-right-width: 1px;
    }
    .sm-border-bottom {
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
    .sm-border-left {
        border-left-style: solid;
        border-left-width: 1px;
    }
    .sm-border-none {
        border: 0
    }
}

@media (min-width: 52em) {
    .md-border {
        border-style: solid;
        border-width: 1px;
    }
    .md-border-top {
        border-top-style: solid;
        border-top-width: 1px;
    }
    .md-border-right {
        border-right-style: solid;
        border-right-width: 1px;
    }
    .md-border-bottom {
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
    .md-border-left {
        border-left-style: solid;
        border-left-width: 1px;
    }
    .md-border-none {
        border: 0
    }
}

@media (min-width: 64em) {
    .lg-border {
        border-style: solid;
        border-width: 1px;
    }
    .lg-border-top {
        border-top-style: solid;
        border-top-width: 1px;
    }
    .lg-border-right {
        border-right-style: solid;
        border-right-width: 1px;
    }
    .lg-border-bottom {
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
    .lg-border-left {
        border-left-style: solid;
        border-left-width: 1px;
    }
    .lg-border-none {
        border: 0
    }
}
