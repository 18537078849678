/**
 *
 * titles
 *
 *
 */

.title-en {
    font-family: var(--font-h);
}

.title-ja {
    font-family: var(--font-yugo);
}


/*=================================
=            sec-title            =
=================================*/

.sec-title {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.15;
    font-weight: 500;
    padding-top: calc(60px/2);
    padding-bottom: calc(52px/2);
    position: relative;
    z-index: 1;
    @media (--md) {
        padding-top: 50px;
        padding-bottom: 54px;
    }
}

.sec-title {
    & .heading-en {
        font-family: var(--font-h);
        font-size: calc(66px/2);
        letter-spacing: 0;
        @media (--md) {
            font-size: 62px;
        }
    }
}

.sec-title {
    & .heading-ja {
        font-size: calc(28px/2);
        letter-spacing: calc(50em/1000);
        @media (--md) {
            font-size: 18px;
        }
    }
}

.sec-title {
    & .heading-upper {
        font-size: calc(58px/2);
        letter-spacing: 0;
        @media (--md) {
            font-size: 56px;
        }
    }
}

.sec-title {
    & .heading-lower {
        @media (--md) {
            font-size: 26px;
        }
    }
}


/*=====  End of sec-title  ======*/


/*============================================
=                 Shop page                  =
============================================*/

.shop .sec-title {
    @media (--md) {
        padding-top: 48px;
        padding-bottom: 52px;
        & .heading-upper {
            margin-bottom: calc(.5rem + 2px);
        }
    }
}

.genre-title {
    padding: calc(28px/2) 0;
    letter-spacing: 0;
    line-height: 1;
    @media (--md) {
        padding: 27px 0 20px;
    }
}

.genre-title {
    & .title-en {
        font-size: calc(38px/2);
        margin-bottom: .3rem;
        font-weight: 600;
        @media (--md) {
            font-size: 38px;
            margin-bottom: .6rem;
        }
    }
}

.genre-title {
    & .title-ja {
        font-size: calc(28px/2);
        margin-right: -.5em;
        @media (--md) {
            font-size: 18px;
        }
    }
}


/*=====  End of Shop page genre-title  ======*/
