/**
 *
 * header-nav
 *
 *
 */

.header-nav {
    & .nav-item {
        @media (--md) {
            display: inline-block;
        }
    }
}

.header-nav {
    & .nav-link {
        display: block;
    }
    & .nav-link.current {
        @media (--md) {
            border-bottom: 1px solid var(--c_black);
        }
    }
}


/*----------  nav btn  ----------*/

@media (--in-sm) {
    .btn-menu {
        width: calc(38px /2);
        height: calc(38px/2);
        border: 0;
        background-image: url(../images/icon-menu.svg);
        background-repeat: no-repeat;
        background-position: center;
        transition: none;
        &:focus, &:active, &:hover {
            border: 0;
            box-shadow: none;
            opacity: 1;
        }
    }
    .btn-menu.btn-close {
        width: calc(56px/2);
        height: calc(56px/2);
        top: 6px;
        background-image: url(../images/icon-close.svg);
    }
}


/*----------  nav modal  ----------*/

@media (--in-sm) {
    .header-nav {
        & .nav-list {
            font-size: 0;
            letter-spacing: 0;
            padding-top: calc(130px/2);
            width: 100%;
            min-height: calc(100vh + 50px);
            background-color: var(--c_light-orange);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }
}


/*----------  nav-items  ----------*/

.header-nav {
    & .nav-item {
        border-bottom: 1px solid var(--c_gray);
        font-size: calc(38px/2);
        line-height: 1.25;
        letter-spacing: calc(80em/1000);
        @media (--md) {
            border: 0;
            font-size: 18px;
        }
    }
}

.header-nav {
    & .nav-link {
        &:hover {
            opacity: 1;
            @media (--md) {
                color: var(--c_gray);
            }
        }
    }
}

.header-nav {
    & .sns-item {
        letter-spacing: 0;
    }
}

.header-nav {
    & .sns-link {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.header-nav {
    & .link-facebook {
        width: calc(70px/2);
        height: calc(70px/2);
        background-image: url(../images/icon-facebook.png);
    }
}

.header-nav {
    & .link-instagram {
        width: calc(62px/2);
        height: calc(70px/2);
        background-image: url(../images/icon-instagram.png);
    }
}

@media (min-width: 832px) and (max-width: 1000px) {
    .header-nav {
        & .nav-item {
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }
}
