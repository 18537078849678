/**
 *
 * main-visual
 *
 *
 */

.mv-area {
    width: 100%;
    height: 0;
    padding-top: calc(878/750*100%);
    background-size: cover;
    background-position: center;
    @media (--md) {
        padding-top: calc(670/1200*100%);
    }
}
