/**
 *
 * Embedded Content
 *
 * figure, image, iframe, embed, object,
 * param, video, audio, source, canvas,
 * map, area
 *
 */


/*==============================
=            images            =
==============================*/

img {
    width: 100%;
}

.fit {
    max-width: none;
}

.square {
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-position: center;
    background-size: cover;
}


/*=====  End of images  ======*/


/*==============================
=            iframe            =
==============================*/

.embed-container {
    width: 100%;
    height: 0;
    overflow: hidden;
}

.embed-container iframe {
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
}


/*----------  トップページのマップ  ----------*/

.g-map {
    padding-top: calc(428/750*100%);
    @media (--md) {
        padding-top: calc(586/1280*100%)
    }
}


/*----------  記事詳細ページの動画  ----------*/

.block-movie {
    padding-top: calc(315/560*100%);
}


/*=====  End of iframe  ======*/
