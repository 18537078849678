/**
 *
 * Layout
 *
 */

.main-content {
    min-height: calc(100vh - 200px);
    @media (--md) {
        padding-top: 80px;
        min-height: calc(100vh - 205px);
    }
}

.container {
    width: 100%;
}

.col1 {
    width: 10%;
}

.col2 {
    width: 20%;
}

.col3 {
    width: 30%;
}

.col4 {
    width: 40%;
}

.col5 {
    width: 50%;
}

.col6 {
    width: 60%;
}

.col7 {
    width: 70%;
}

.col8 {
    width: 80%;
}

.col9 {
    width: 90%;
}

.col10 {
    width: 100%;
}

@media (--md) {
    .md-col1 {
        width: 10%;
    }
    .md-col2 {
        width: 20%;
    }
    .md-col3 {
        width: 30%;
    }
    .md-col4 {
        width: 40%;
    }
    .md-col5 {
        width: 50%;
    }
    .md-col6 {
        width: 60%;
    }
    .md-col7 {
        width: 70%;
    }
    .md-col8 {
        width: 80%;
    }
    .md-col9 {
        width: 90%;
    }
    .md-col10 {
        width: 100%;
    }
}
