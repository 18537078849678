/**
 *
 * footer-links
 *
 *
 */

.footer-links {
    & .footer-link {
        width: calc(72px/2);
        height: calc(72px/2);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @media (--md) {
            width: 48px;
            height: 48px;
        }
    }
}

.footer-links {
    & .link-instagram {
        width: calc(64px/2);
        height: calc(64px/2);
        @media (--md) {
            width: 42px;
            height: 42px;
        }
    }
}

.footer-links {
    & .link-facebook {
        background-image: url(../images/icon-facebook.png);
    }
    & .link-instagram {
        background-image: url(../images/icon-instagram.png);
    }
    & .link-contact {
        background-image: url(../images/icon-contact.png);
    }
    & .link-form {
        background-image: url(../images/icon-form.png);
    }
}

.footer-links {
    & .link-contact, & .link-form {
        & span {
            width: calc(100% + 3.5em);
            padding: 0 .5em;
            font-size: 12px;
            line-height: calc(21/12);
            letter-spacing: 0;
            opacity: 0;
            transition: all .3s;
            left: 50%;
            bottom: -2.2em;
            transform: translateX(-50%);
        }
        &:hover {
            &>span {
                opacity: 1;
            }
        }
    }
}
