/**
 *
 * buttons
 *
 *
 */

.btn-large {
    width: calc(480px/2);
    line-height: calc(120px/2);
    padding: 0;
    font-size: calc(40px/2);
    letter-spacing: calc(50em/1000);
    color: var(--c_black);
    border: 2px solid var(--c_black);
    @media (--md) {
        width: calc(348px - 2px*2);
        line-height: calc(100px - 2px*2);
        font-size: 23px;
        letter-spacing: calc(80em/1000);
        color: var(--c_white);
        background-color: var(--c_black);
        &:hover, &:active, &:focus {
            color: var(--c_white);
        }
    }
}

.btn-totop {
    @media (--in-sm) {
        /* position: absolute; */
        width: calc(52px/2);
        height: calc(52px/2);
        margin: auto;
        left: calc(50% - (52px/4));
        background-image: url(../images/icon-totop.svg);
        &.fixed {
            position: fixed;
            opacity: 1;
            bottom: 16px;
        }
    }
}

.btn-scroll {
    @media (--md) {
        content: '';
        width: 48px;
        height: 48px;
        left: 50%;
        background-image: url(../images/icon-arrow-down.svg);
        transform: translate(-50%, -16px);
        transition: all .3s;
        &:hover {
            transform: translate(-50%, -12px);
        }
    }
}
