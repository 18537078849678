/**
 *
 * sidebar
 *
 *
 */

.news-sidebar {
    &::before {
        width: 1px;
        height: calc(100% + 64px);
        background-color: var(--c_gray);
        position: absolute;
        right: calc(100% - 32px);
        top: -64px;
        @media (--md) {
            display: block;
            content: '';
        }
    }
}

.news-sidebar {
    @media (--in-sm) {
        background-color: var(--theme-gray);
        border-bottom: 1px solid var(--c_gray);
    }
    @media (--md) {
        background-color: var(--c_white);
    }
}

.news-sidebar {
    & h2 {
        font-size: 16px;
        @media (--in-sm) {
            font-size: calc(30px/2);
            font-weight: normal;
        }
    }
}

.news-sidebar {
    & .hyphen, & h2 {
        @media (--in-sm) {
            color: #4c4c4c;
        }
    }
}

.news-sidebar {
    & ul {
        line-height: calc(64/30);
        @media (--md) {
            line-height: 1.2;
        }
    }
}

.news-sidebar {
    & .item-link {
        @media (--md) {
            padding: .3rem 0;
        }
    }
}


/*=======================================
=            news index page            =
=======================================*/

.news-index {
    & .news-sidebar {
        @media (--md) {
            padding-top: 64px;
        }
    }
}

.news-index {
    & .news-sidebar {
        &::before {
            height: 100%;
            top: 0;
        }
    }
}


/*=====  End of news index page  ======*/
